<template>
  <div>
    <Card>
      <template #header>
        <div class="baslik w-100">
          <div>Satıcılar</div>
          <div>
            <router-link to="bank-new">
              <button class="iconPlus btn btn-secondary">
                <p style="font-size: 13px">
                  <i class="flaticon2-plus mb-1"></i>Satıcı
                </p>
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <hr class="w-100" />
      </div>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.id="{ item }">
          <div class="circle" >{{ item.fullName.charAt(0) }}</div>
        </template>
        <template v-slot:item.fullName="{ item }">
          <router-link to="bank-details">
            <div class="arrowItem">
              <div>
                <p style="color: #333333; font-size: 16px;">{{ item.fullName }}</p>
                <!-- <p>{{ item.job }}</p> -->
              </div>
              <div>
                <i class="flaticon2-next"></i>
              </div>
            </div>
          </router-link>
        </template>
      </List>
      <!-- Create and Actions Popups -->
      <InsertUpdateModal
        :title="
          selectedItem == ''
            ? 'Sistem Kullanıcı Kaydı Ekle'
            : 'Sistem Kullanıcını Düzenle'
        "
        :show="showInsertUpdate"
        @closeDetail="closeInsertUpdate()"
        @confirm="confirmModal()"
        :resetForm="resetForm"
      >
        <InsertUpdateOperations
          :selectedItem="selectedItem"
          :confirmModal="confirmCreate"
          @resetInsertUpdateForm="resetInsertUpdateForm"
        />
      </InsertUpdateModal>

      <DetailModal
        title="Fiili Kullanım Nitelikleri"
        :show="showDetail"
        @openInsertUpdate="openInsertUpdate(selectedItem)"
        @closeDetail="closeDetail"
      >
        <DetailView :selectedItem="selectedItem" />
      </DetailModal>

      <ImportModal
        :show="showImportModal"
        importApiEndpoint="user/import"
        @closeDetail="closeImportModal()"
        @updateList="getList"
        @downloadSampleFile="exportOperations(true)"
      />

      <DeleteOperation
        :url="deleteOperationUrl"
        :params="deleteIds"
        @updateList="getList"
      />
    </Card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "systemUsers",
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "#", value: "id", size: "60px" },
        { text: "İsim", value: "fullName" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satıcılar", route: "/manage/bank" },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [
        {
          id: "1",
          fullName: "Şekerbank",
        },
        {
          id: "2",
          fullName: "Odebank",
        },
        {
          id: "3",
          fullName: "Vakıf Bank",
        },
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    confirmModal() {
      this.confirmCreate = true;
      setTimeout(() => {
        this.confirmCreate = false;
      }, 200);
    },
    resetInsertUpdateForm(closeInsertUpdate = false) {
      if (closeInsertUpdate) {
        this.closeInsertUpdate();
        this.getList();
      }

      this.resetForm = true;
      setTimeout(() => {
        this.resetForm = false;
      }, 200);
    },
    closeInsertUpdate() {
      setTimeout(() => {
        this.selectedItem = "";
      }, 100);
      this.showInsertUpdate = false;
    },
    openInsertUpdate(item) {
      this.selectedItem = item;
      this.showInsertUpdate = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    openDetail(item) {
      this.selectedItem = item;
      this.showDetail = true;
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    openImportModal() {
      this.showImportModal = true;
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `user`;
      this.deleteIds = item.id;
      this.$bvModal.show("modal-deleteOperation");
    },
    selectedItemIdsChanged(payload) {
      this.listSelectedItemIds = payload;
    },
    actions(payload) {
      if (
        this.listSelectedItemIds === [] ||
        this.listSelectedItemIds.length == 0
      ) {
        this.$generateNotification(
          this,
          "warning",
          "İşlem yapılacak seçili kayıt bulunamadı!",
          "İşleme devam edilemiyor."
        );
      } else {
        if (payload == "allRemove") {
          this.deleteOperationUrl = `user`;
          this.deleteIds = this.listSelectedItemIds;
          this.$bvModal.show("modal-deleteOperation");
        } else if (payload == "allInActive" || payload == "allActive") {
          this.loading = true;
          this.$dbFunctions
            .update(`user/update-status`, {
              ids: this.listSelectedItemIds,
              isActive: payload == "allActive",
            })
            .then((res) => {
              this.$dbFunctions.setResponse(this, res);
              this.getList();
            })
            .catch((err) => {
              this.$dbFunctions.setResponse(this, err);
            })
            .finally(() => {
              this.loading = false;
            });
        } else if (payload == "allExport") {
          this.exportOperations(false);
        }
      }
    },
    exportOperations(onlyHeaders) {
      this.loading = true;
      let payload = {
        Ids: this.listSelectedItemIds,
        onlyHeaders: onlyHeaders,
      };

      this.$dbFunctions
        .insertOrUpdate(`user/export`, "export", payload)
        .then((res) => {
          // this.$customFunctions.downloadFile(res.data);

          this.$generateNotification(
            this,
            "success",
            `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
            "Dosyanız hazır!"
          );
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  components: {
    InsertUpdateOperations: () =>
      import("@/components/systemUser/modals/InsertUpdateOperations"),
    DetailView: () => import("@/components/systemUser/modals/DetailView"),
  },
};
</script>

<style scoped>
.iconPlus {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 8px;
  border-radius: 10px;
}
.baslik {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.arrowItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
}
</style>
